.Modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 101;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.Modal .Modal-Card {
  padding: 0;
  box-shadow: 0rem 0.25rem 1rem 0rem #00000056;
  border-radius: 9px;
  position: relative;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--main-background-color);
  border: 1px solid var(--dropdown-border-color);

}

.Modal-Header, .Modal-Footer {
  padding: 20px;
  display: flex;
  align-items: center;
}

.Modal-Header .Close-Button {
  height: 30px;
  width: 30px;
  min-width: 30px;
  outline: none;
  border: none;
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal-Header .Close-Button:hover {
  background-color: var(--button-hover-color);
}

.Modal-Header .Close-Button .Close-Icon {
  fill: var(--primary-icon-color);
}

.Modal-Header-Text {
  flex: 1;
  font-size: 20px;
  font-weight: 600;
  color: var(--primary-text-color);
}

.New-Project-Modal .Modal-Card {
  width: 450px;
  max-width: 100%;
}

.New-Project-Modal .Input-Wrapper {
  margin: 0 20px;
}

.New-Project-Modal .Input-Wrapper input {
  width: 100%;
}

.New-Project-Modal .Create-Project-Button {
  margin-left: 15px;
}